import React from "react";
import ContentBlock from "../components/v2/ContentBlock";

import { pageLinks } from "../data/pageLinks";

import Layout from "../components/v2/Layout";
import PageSection from "../components/v2/PageSection";
import ImageScroller from "../components/v2/ui-blocks/ImageScroller";
import Image from "../components/v2/Image";
import BC_Colours from "../images/12. Brand Campaigns/BC_Colours.jpg";
import Hilux_1 from "../images/12. Brand Campaigns/BC_Displayfont_Hilux_1.png";
import Hilux_2 from "../images/12. Brand Campaigns/BC_Displayfont_Hilux_2.png";
import Yaris_1 from "../images/12. Brand Campaigns/BC_Displayfont_Yaris_1.png";
import Yaris_2 from "../images/12. Brand Campaigns/BC_Displayfont_Yaris_2.png";
import Yaris_3 from "../images/12. Brand Campaigns/BC_Displayfont_Yaris_3.png";
import Yaris_Hero from "../images/12. Brand Campaigns/BC_Yaris_Hero.jpg";
import PrevNextNavFooter from "../components/v2/ui-blocks/PrevNextNavFooter/PrevNextNavFooter";

import empty_png from "../images/placeholder_800x500.png";

const BrandCampaigns = ({ location }) => {
  return (
    <Layout appLocation={location}>
      <div className="hero-img">
        <Image width="100%" src={Yaris_Hero} />
      </div>
      <PageSection sectionId="overview">
        <ContentBlock>
          <h2>Brand campaigns</h2>
          <div className="text">
            <p>The following provides guidelines for Brand Campaigns.</p>
            <p>
              Whether a vehicle or a sub-brand is communicated via a Brand
              Campaign will be determined at the briefing stage. They will often
              include, but is not limited to, campaigns for our brand icons:
              Hilux, RAV4, Corolla and Yaris.
            </p>
            <p>
              There is plenty of room for flexibility to allow Brand Campaigns
              to have their own distinctive look and tone, but there are still
              some guidelines to follow to ensure they feel like a Toyota
              campaign.
            </p>
          </div>
        </ContentBlock>
      </PageSection>
      <PageSection sectionId="display-fonts">
        <ContentBlock>
          <h2>DISPLAY FONTS</h2>
          <div className="text">
            <p>
              When it comes to Brand Campaigns, typography plays an important
              part in expressing an idea or mood. Because of this, there is the
              flexibility to branch outside of Toyota Type and choose a
              different display font for headlines or subheads. There is also
              the option to use any font in the Toyota Type family if it works
              for the campaign idea.
            </p>
            <p>
              Toyota Type will still be used as the secondary typeface for all
              other copy ie. body copy, legal text etc.
            </p>
            <p>
              Below are some examples of Brand Campaigns that utilise unique
              display fonts.
            </p>
          </div>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: Hilux_1,
                style: { height: "auto", width: "600px" }
              }
            },
            {
              image: {
                svg: Hilux_2,
                style: { height: "auto", width: "600px" }
              }
            },
            {
              image: {
                svg: Yaris_1,
                style: { height: "auto", width: "600px" }
              }
            },
            {
              image: {
                svg: Yaris_2,
                style: { height: "auto", width: "600px" }
              }
            },
            {
              image: {
                svg: Yaris_3,
                style: { height: "auto", width: "600px" }
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PageSection sectionId="campaign-colours">
        <ContentBlock>
          <h2>CAMPAIGN COLOURS</h2>
          <div className="text">
            <p>
              Brand Campaigns don’t need to adhere to the Toyota colour pallet
              (red, white and black). A new colour pallet can be created to suit
              the campaign idea.
            </p>
            <p>
              Campaign colours can be created by taking cues from other colours
              featured in the artwork. For example, from imagery or textures.
            </p>
            <p>
              These colours can also be used as tints to help ensure there’s
              plenty of contrast between key messaging, logos and graphics.
            </p>
          </div>
        </ContentBlock>
        <ContentBlock></ContentBlock>
        <Image width="full" src={BC_Colours} />
      </PageSection>
      <PageSection sectionId="layout-examples">
        <ContentBlock>
          <h2>Layout Examples</h2>
        </ContentBlock>
        <ImageScroller
          style={{ backgroundColor: "white" }}
          images={[
            {
              image: {
                svg: empty_png,
                style: { height: "800px", width: "500px" }
              },
              caption: { copy: "Hyperlink captions will sit under artwork." }
            },
            {
              image: {
                svg: empty_png,
                style: { height: "500px", width: "800px" }
              },
              caption: {
                copy: "Hyperlink captions will sit under artwork."
              }
            },
            {
              image: {
                svg: empty_png,
                style: { height: "500px", width: "800px" }
              },
              caption: {
                copy: "Hyperlink captions will sit under artwork."
              }
            }
          ]}></ImageScroller>
      </PageSection>
      <PrevNextNavFooter
        previousText="Previous"
        previousHeader="Hey Toyota"
        previousUrl={pageLinks["hey-toyota"]}
        nextText="Next"
        nextHeader="Partnerships"
        nextUrl={pageLinks.partnerships}
      />
    </Layout>
  );
};

export default BrandCampaigns;